import { createApi } from '@reduxjs/toolkit/query/react';

import { accountApi } from '../account/account';
import customFetchBase from '../customFetchBase';

import { TokenService } from '@/shared/tokens/tokens.service';

import { IApiResponse } from '@/shared/types/app';

import { IAuth } from './interfaces/auth.interface';

import { LoginInput } from '@/store/validation-schemas/login.validation';
import { ForgotPasswordInput } from '@/store/validation-schemas/forgot-password.validation';
import { CreateUserInput } from '@/store/validation-schemas/create-user.validation';

import { UserRoleEnum } from '@/shared/role.enum';
import { EAccount } from '../account/enums/account.enum';
import { EAuth } from './enums/auth.enum';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  tagTypes: [EAccount.TagsType.USER, EAuth.TagsType.Refresh],
  endpoints: build => ({
    loginUser: build.mutation<IAuth.LoginResponse, LoginInput>({
      query: data => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<IAuth.LoginResponse>) => {
        const { data } = result;

        TokenService.setTokens(data.accessToken, data.refreshToken);

        return data;
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     // await queryFulfilled;
      //     // dispatch(accountApi.util.invalidateTags([{ type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT }]));
      //     // await dispatch(accountApi.endpoints.getMe.initiate(null));
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),
    logoutUser: build.mutation<IApiResponse<void>, void>({
      query() {
        return {
          url: 'auth/logout',
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
        EAuth.TagsType.Refresh,
      ],
      transformResponse: (result: IApiResponse<void>) => {
        TokenService.clearTokens();
        return result;
      },
    }),
    forgotPassword: build.mutation<
      IAuth.ForgotPasswordResponse,
      ForgotPasswordInput
    >({
      query(body) {
        return {
          url: `auth-security/send-mail-with-reset-password-url`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
      transformResponse: (
        result: IApiResponse<IAuth.ForgotPasswordResponse>,
      ) => {
        return result.data;
      },
    }),
    resetPassword: build.mutation<void, IAuth.ForgotPasswordPayload>({
      query({ token, password }) {
        return {
          url: `auth-security/reset-password`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: { password },
        };
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
      transformResponse: (result: IApiResponse<void>) => {
        return result.data;
      },
    }),
    createUser: build.mutation<IAuth.LoginResponse, CreateUserInput>({
      query({ firstName, lastName, password, email }) {
        return {
          url: `auth/sign-up`,
          method: 'POST',
          body: {
            firstName,
            lastName,
            email,
            password,
            role: UserRoleEnum.COACH,
          },
        };
      },
      invalidatesTags: [
        { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
      ],
      transformResponse: (result: IApiResponse<IAuth.LoginResponse>) => {
        TokenService.setTokens(
          result.data.accessToken,
          result.data.refreshToken,
        );
        return result.data;
      },
      async onQueryStarted(
        args: CreateUserInput,
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          await dispatch(
            accountApi.endpoints.patchPersonalData.initiate({
              userId: data.user.id,
              rate: args.rate,
            }),
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    refreshToken: build.query<IApiResponse<IAuth.RefreshTokenResponse>, void>({
      query() {
        const refreshToken = TokenService.getRefreshToken();
        return {
          url: `auth/refreshToken?refreshToken=${refreshToken}`,
          method: 'GET',
        };
      },
      providesTags: () => [EAuth.TagsType.Refresh],
      transformResponse: (result: IApiResponse<IAuth.RefreshTokenResponse>) => {
        const { data } = result;

        TokenService.setTokens(data.accessToken, data.refreshToken);
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            accountApi.util.invalidateTags([
              { type: EAccount.TagsType.USER, id: EAccount.IdsType.CURRENT },
            ]),
          );
          await dispatch(accountApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCreateUserMutation,
} = authApi;
