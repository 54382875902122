import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { IApiPagination, IApiResponse } from '@/shared/types/app';
import { ISubscription } from './interfaces/subscription.interface';
import { ESubscription } from './enums/subscription.enum';
import { EWallet } from '../wallet/enums/wallet.enum';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: customFetchBase,
  tagTypes: [
    ESubscription.TagsType.Subscription,
    EWallet.TagsType.PayInInvoice,
    EWallet.TagsType.GroupedPayInInvoice,
  ],
  endpoints: build => ({
    getSubscriptionPlans: build.query<ISubscription.Subscription[], null>({
      query() {
        return {
          url: '/b2b-offerings/unauthorized-subscription-plans',
          method: 'GET',
        };
      },
      transformResponse: (
        result: IApiResponse<IApiPagination<ISubscription.Subscription>>,
      ) => {
        const { data } = result;

        return data.items;
      },
      providesTags: [ESubscription.TagsType.Subscription],
    }),
    getCurrentSubscriptionPlan: build.query<ISubscription.Offer, null>({
      query() {
        return {
          url: '/b2b-offerings/offers/get-current-subscription-plan',
          method: 'GET',
        };
      },
      transformResponse: (result: IApiResponse<ISubscription.Offer>) => {
        return result.data;
      },
      providesTags: [ESubscription.TagsType.Subscription],
    }),
    getPaymentLinkToPurchaseSubscriptionPlan: build.mutation<
      string,
      ISubscription.PPaymentLink
    >({
      query: data => ({
        url: '/b2b-offerings/offers/get-payment-link-to-purchase-subscription-plan',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<string>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
    cancelCurrentSubscriptionPlan: build.mutation<
      ISubscription.Subscription,
      null
    >({
      query: () => ({
        url: '/b2b-offerings/offers/cancel-current-subscription-plan',
        method: 'POST',
      }),
      transformResponse: (result: IApiResponse<ISubscription.Subscription>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
    getPaymentLinkToChangeSubscriptionPlan: build.mutation<
      string,
      ISubscription.PChangeSubscriptionPlan
    >({
      query: data => ({
        url: '/b2b-offerings/offers/get-payment-link-to-change-subscription-plan',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<string>) => {
        return result.data;
      },
      invalidatesTags: [
        ESubscription.TagsType.Subscription,
        EWallet.TagsType.PayInInvoice,
        EWallet.TagsType.GroupedPayInInvoice,
      ],
    }),
  }),
});

export const {
  useGetSubscriptionPlansQuery,
  useGetPaymentLinkToPurchaseSubscriptionPlanMutation,
  useCancelCurrentSubscriptionPlanMutation,
  useGetCurrentSubscriptionPlanQuery,
  useGetPaymentLinkToChangeSubscriptionPlanMutation,
} = subscriptionApi;
