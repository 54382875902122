import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { IApiPagination, IApiResponse } from '@/shared/types/app';
import { IWallet } from './interfaces/wallet.interface';
import { EWallet } from './enums/wallet.enum';

export const walletApi = createApi({
  reducerPath: 'walletApi',
  baseQuery: customFetchBase,
  tagTypes: [
    EWallet.TagsType.PayInInvoice,
    EWallet.TagsType.GroupedPayInInvoice,
  ],
  endpoints: build => ({
    getPayInInvoices: build.query<
      IWallet.PayInInvoice[],
      IWallet.PPayInInvoice
    >({
      query({ status }) {
        return {
          url: `/b2b-wallets/coach/pay-in-invoices?${status ? `status=${status}` : ''}`,
          method: 'GET',
        };
      },
      transformResponse: (
        result: IApiResponse<IApiPagination<IWallet.PayInInvoice>>,
      ) => {
        const { data } = result;

        return data.items;
      },
      providesTags: [EWallet.TagsType.PayInInvoice],
    }),
    getPayInInvoiceDetails: build.query<
      IWallet.PayInInvoiceDetails,
      IWallet.PPayInInvoiceDetails
    >({
      query({ id }) {
        return {
          url: `/b2b-wallets/coach/pay-in-invoices/${id}/details`,
          method: 'GET',
        };
      },
      transformResponse: (
        result: IApiResponse<IWallet.PayInInvoiceDetails>,
      ) => {
        const { data } = result;

        return data;
      },
      providesTags: [EWallet.TagsType.PayInInvoice],
    }),
    getGroupedPayInInvoices: build.query<IWallet.GroupedPayInInvoice[], null>({
      query() {
        return {
          url: `/b2b-wallets/coach/grouped-pay-in-invoices`,
          method: 'GET',
        };
      },
      transformResponse: (
        result: IApiResponse<IApiPagination<IWallet.GroupedPayInInvoice>>,
      ) => {
        const { data } = result;

        return data.items;
      },
      providesTags: [EWallet.TagsType.GroupedPayInInvoice],
    }),
  }),
});

export const {
  useGetPayInInvoicesQuery,
  useGetGroupedPayInInvoicesQuery,
  useGetPayInInvoiceDetailsQuery,
} = walletApi;
