import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';

export const countryApi = createApi({
  reducerPath: 'countryApi',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getCountryCodes: build.query<string[], void>({
      query: () => ({
        url: '/stripe-countries',
        method: 'GET',
      }),
      transformResponse: (result: any) => {
        return result.data.map((country: { label: string }) => country.label);
      },
    }),
  }),
});

export const { useGetCountryCodesQuery } = countryApi;
