import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { WeatherApiResponseInterface } from './interfaces/weather-api-response.interface';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getActivityStatistics: build.query<
      WeatherApiResponseInterface,
      { dateGrouping: string }
    >({
      query: () => ({
        url: '/user-contacts/plan-golfers/get-activity-statistics',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetActivityStatisticsQuery } = dashboardApi;
