import { createApi } from '@reduxjs/toolkit/query/react';

import customFetchBase from '../customFetchBase';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customFetchBase,
  tagTypes: ['Users'],
  endpoints: builder => ({
    //TODO add actions here
  }),
});

export const {
  //TODO import actions here
} = usersApi;
