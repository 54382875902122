export namespace EWallet {
  export enum InvoicePaymentStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
    FAILED = 'failed',
    PAID = 'paid',
    AUTHORISED = 'authorised',
    CANCELED = 'canceled',
    REFUNDED = 'refunded',
  }

  export enum InvoiceGroup {
    SUBSCRIPTION_PLAN = 'subscription-plan',
  }

  export enum InvoiceFeeType {
    SERVICE = 'service',
    PROVIDER = 'provider',
    BALANCE = 'balance',
  }

  export enum InvoiceItemType {
    OFFER = 'offer',
  }

  export enum InvoiceCouponType {
    DISCOUNT = 'discount',
    FREE = 'free',
  }

  export enum TagsType {
    PayInInvoice = 'pay-in-invoice',
    GroupedPayInInvoice = 'grouped-pay-in-invoice',
  }
}
