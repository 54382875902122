export namespace ESubscription {
  export enum Periods {
    MONTH = 'month',
    YEAR = 'year',
  }

  export enum Features {
    DEFAULT = 'default',
    BENEFITS = 'benefits',
  }

  export enum Status {
    PENDING = 'pending',
    ACTIVE = 'active',
    CANCELED = 'canceled',
  }

  export enum Types {
    SUBSCRIPTION_PLAN = 'subscription-plan',
  }

  export enum TagsType {
    Subscription = 'subscription',
  }
}
