export namespace EAuth {
  export enum VerificationType {
    EMAIL = 'email',
    PHONE = 'phone',
    RESET_PASSWORD = 'reset-password',
  }

  export enum TagsType {
    Refresh = 'refresh',
  }
}
