'use client';

import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from '@/store/store';
import { ServerErrorModal } from '@/components/common';
import { setServerError } from '@/store/features/app/slice';

export const ServerErrorWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const serverError = useAppSelector(
    state => state.app.serverError,
    shallowEqual,
  );

  return (
    <>
      {children}
      <ServerErrorModal
        isOpen={serverError}
        onClose={() => dispatch(setServerError(false))}
      />
    </>
  );
};
